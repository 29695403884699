/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Divider,
  Link,Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Group from "./elements/Group";
import Switch from "./elements/Switch";
import { UserContext } from "./../../context/UserContext";
import { messaging, onMessageListener } from "../../firebase";
import { getToken } from "firebase/messaging";
// import mqtt from "mqtt";
import mqtt from "precompiled-mqtt";
import Loader from "../../components/Loader";
import { subscribeNotification } from "../../api/notification";
import { getGroupWiseDevices } from "../../api/group";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Dashboard = () => {
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [group, setGroup] = useState([]);

  const [client, setClient] = useState();
  const [clientLoad, setClientLoad] = React.useState(true);
  const [reconnect, setReconnect] = useState(false);
  const [idInterval, setIdInterval] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [isTokenFound, setIsTokenFound] = useState(false);
  const navigate=useNavigate();
  const connectClient = () => {
    const token = window.localStorage.getItem("auth-token");
    const options = {
      keepalive: 30,
      clean: true,
      reconnectPeriod: 500,
      rejectUnauthorized: false,
      username: userData.user.phone_no,
      password: userData.user.token,
    };

    const client_temp = mqtt.connect(
      `wss://${process.env.REACT_APP_MQTT_BROKER_URL}`,
      options
    );
    setClient(client_temp);
  };

  const reqPer = async () => {
    try {
      setLoading(true);
      if (
        navigator.serviceWorker &&
        !localStorage.getItem("notification_token")
      ) {
        const per = await Notification.requestPermission();
        if (per === "granted") {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
          });
          setIsTokenFound(true);
          //send token here...
          if (!localStorage.getItem("notification_token")) {
            const data = {
              user_id: userData.user._id,
              notification_token: token,
            };
            localStorage.setItem("notification_token", token);
            await subscribeNotification(data);
          }
          // console.log(res);
          setLoading(false);
        }
      }
      // let register = await navigator.serviceWorker.ready;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    connectClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(userData);
    if (!localStorage.getItem("notification_token")) {
      reqPer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (client !== undefined) {
      client.on("connect", function () {
        console.log("CLIENT CONNECTED");
        setReconnect(false);
        setClientLoad(false);
        clearInterval(idInterval);
        setIsConnected(true);
      });

      client.on("error", (err) => {
        console.log("Connection error: ", err);
        console.log("NOT CONNECTED");
        // window.location.reload();
      });
      client.on("offline", () => {
        console.log("OFFLINE");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (client !== undefined) {
      client.on("reconnect", () => {
        if (clientLoad) {
          console.log("ATTEMPTING INITIAL RECONNECTION");
          connectClient();
        } else {
          console.log("RECONNECTING")
          setReconnect(true);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  // const attemptReconnect = () => {
  //   const id = setInterval(() => {
  //     console.log("ATTEMPT reconnect");
  //     connectClient();
  //   }, 5000);
  //   setIdInterval(id);
  // };

  useEffect(() => {
    getGroupWiseDevices(userData.user.company_id._id,setGroup, setLoading, setError);
  }, [userData.user.company_id._id]);

  return (
    <>
    <Box display="flex" justifyContent="space-between">
    <Box>
      {" "}
      <PageTitle>Hi, {userData?.user?.full_name.split(" ")[0]}</PageTitle>
      <Typography
        variant="subtitle1"
        sx={{ mt: -1.6, fontSize: "1rem", color: "#c0c0c0", mb: -1 }}
      >
        Welcome back!
      </Typography>
      
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" }, mt: 1 }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Controls</ActiveLink>
      </Breadcrumbs>
      </Box>
      {/* {userData.companies.length>1&&<Box>
      <Typography
            sx={{ fontSize: "2rem", fontWeight: 600,}}
            color="secondary"
          >
            {localStorage.getItem("company")}
          </Typography>
      <Button fullWidth variant="outlined" color="error" onClick={()=>navigate("/company",{state:{token:userData.token,userData:userData.companies}})}>Change Site</Button>
      </Box>} */}
      </Box>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* Group Mapping */}
              {group.length === 0 ? (
                <p>{error}</p>
              ) : (
                <>
                  {group.map((grp) => {
                    return (
                      <Group header={grp.group_name} key={grp._id}>
                        <Grid container spacing={3}>
                          {/* Device Mapping Here */}
                          {grp.device_data.map((value) => {
                            return (
                              <Grid item key={value.device_id}>
                                <Switch
                                  // Id={value._id}
                                  Id={value.device_id}
                                  client={client}
                                  loading={clientLoad}
                                  reconnect={reconnect}
                                  companyId={
                                    userData.user.company_id.company_code
                                  }
                                  device_data={value}
                                />
                              </Grid>
                            );
                          })}

                          {/* ./Device Mapping */}
                        </Grid>
                      </Group>
                    );
                  })}
                </>
              )}
              {/* ./Group Mapping */}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Dashboard;